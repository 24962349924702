<template>
   <!-- start::Vertical nav menu --->
   <div class="main-menu menu-fixed menu-accordion menu-shadow"
		:class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
		@mouseenter="updateMouseHovered(true)"
		@mouseleave="updateMouseHovered(false)"
   >
	  <!-- start::main menu header-->
	  <div class="navbar-header expanded">
		 <slot name="header"
			   :toggleVerticalMenuActive="toggleVerticalMenuActive"
			   :toggleCollapsed="toggleCollapsed"
			   :collapseTogglerIcon="collapseTogglerIcon">
			<!-- start::nav bar -->
			<ul class="nav navbar-nav flex-row">
			   <!-- start::Logo & application name -->
			   <li class="nav-item mr-auto">
				  <b-link class="navbar-brand"
						  :to="{ name: 'dashboard'} ">
              <span class="brand-logo">
                <b-img :src="appLogoImage"
					   alt="logo" />
              </span>
					 <h2 class="brand-text">{{ appName }}</h2>
				  </b-link>
			   </li>
			   <!-- end::Logo & application name -->

			   <!-- start::toggle button -->
			   <li @click="onSidebar"
				   class="nav-item nav-toggle">
				  <b-link class="nav-link modern-nav-toggle">
					 <feather-icon icon="XIcon"
								   size="20"
								   class="d-block d-xl-none"
								   @click="toggleVerticalMenuActive" />
					 <feather-icon :icon="collapseTogglerIconFeather"
								   size="20"
								   class="d-none d-xl-block collapse-toggle-icon"
								   @click="toggleCollapsed" />
				  </b-link>
			   </li>
			   <!-- end::toggle button -->
			</ul>
			<!-- end::nav bar -->
		 </slot>
	  </div>
	  <!-- end:: main menu header-->

	  <!-- start::Shadow -->
	  <div :class="{'d-block': shallShadowBottom}"
		   class="shadow-bottom" />
	  <!-- end::Shadow -->

	  <!-- start::main menu content-->
	  <vue-perfect-scrollbar :settings="perfectScrollbarSettings"
							 class="main-menu-content scroll-area"
							 tagname="ul"
							 @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
		 <vertical-nav-menu-items :items="navMenuItems"
								  class="navigation navigation-main" />
	  </vue-perfect-scrollbar>
	  <!-- end::main menu content-->
   </div>
   <!-- end::Vertical nav menu --->
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {BLink, BImg} from 'bootstrap-vue'
import {provide, computed, ref} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {$themeConfig} from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import store from "@/store";
import axiosIns from "@/libs/axios";

export default {
   components: {
	  VuePerfectScrollbar,
	  VerticalNavMenuItems,
	  BLink,
	  BImg,
   },
   props: {
	  isVerticalMenuActive: {
		 type: Boolean,
		 required: true,
	  },
	  toggleVerticalMenuActive: {
		 type: Function,
		 required: true,
	  },
   },
   setup(props) {
	  const {
		 isMouseHovered,
		 isVerticalMenuCollapsed,
		 collapseTogglerIcon,
		 toggleCollapsed,
		 updateMouseHovered,
	  } = useVerticalNavMenu(props)

	  const {skin} = useAppConfig()


	  store.dispatch('fetchPreferencesSettings').then(() => {
		 isVerticalMenuCollapsed.value = store.getters.getSidebarOpen
	  })

	  const onSidebar = async () => {
		 const {data} = axiosIns.put('/user-preferences', {
			sidebarOpen: isVerticalMenuCollapsed.value ? 1 : 0
		 })
	  }

	  // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
	  const shallShadowBottom = ref(false)

	  provide('isMouseHovered', isMouseHovered)

	  const perfectScrollbarSettings = {
		 maxScrollbarLength: 60,
		 wheelPropagation: false,
	  }

	  const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

	  // App Name
	  const {appName, appLogoImage} = $themeConfig.app

	  return {
		 navMenuItems,
		 perfectScrollbarSettings,
		 isVerticalMenuCollapsed,
		 collapseTogglerIcon,
		 toggleCollapsed,
		 isMouseHovered,
		 updateMouseHovered,
		 collapseTogglerIconFeather,
		 // Shadow Bottom
		 shallShadowBottom,
		 onSidebar,

		 // Skin
		 skin,

		 // App Name
		 appName,
		 appLogoImage,
	  }
   },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>

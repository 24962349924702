<template>
   <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
		  class="ml-25"
		  href="#"
		  target="_blank"
	  >Beezio</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

	  <b-link
	  		id="tour-support"
		  class="float-md-right d-none d-md-block"
		  :to="{name:'support'}"

	  >{{ $t('generic.support') }}
	  </b-link>
	  <b-link
		  id="tour-sub"
		  class="float-md-right d-none d-md-block mr-1"
		  :to="{name:'subscription'}"

	  >
		 {{ $t('generic.subscription') }}
	  </b-link>
   </p>
</template>

<script>
import {BLink} from 'bootstrap-vue'

export default {
   components: {
	  BLink,
   },
}
</script>

<template>
   <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
	  <feather-icon
		  @click="changeMode"
		  size="21"
		  :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
	  />
   </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {computed} from '@vue/composition-api'
import {BNavItem} from 'bootstrap-vue'
import axiosIns from "@/libs/axios";
import store from "@/store";

export default {
   components: {
	  BNavItem,
   },
   setup() {
	  const {skin} = useAppConfig()

	  const isDark = computed(() => skin.value === 'dark')

	 async function changeMode() {

		await axiosIns.put('/user-preferences', {
			"nightModeActive": 0,
		 })
		await store.dispatch('changeNightMode',false);


	  }

	  return {skin, isDark, changeMode}
   },
}
</script>

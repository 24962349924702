<!--NOTE: πρέπει να γίνει global component, θα το έχουμε κ σε άλλα σημεία στο app -->

<template>
   <b-sidebar
	   :id="id"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
			<h5 class="mb-0">
			   {{ $t('projects.add-payment') }}
			   <!-- NOTE: στο παλιό αυτό άλλαζε αναλόγως του τι πάταγες πριν αλλά δεν μπορώ να το βρω -->
			</h5>
			<feather-icon class="ml-1 cursor-pointer"
						  icon="XIcon"
						  @click.prevent="closeModal"
						  size="16" />
		 </div>
		 <validation-observer ref="observer"
							  v-slot="{handleSubmit , invalid}"
		 >
			<b-form class="p-2"
					@submit.prevent="onSubmitPayments">

			   <!-- start::select contact -->
			   <!-- όταν είσαι μέσα στην καρτέλα του πελάτη αυτό να είναι προεπιλγμένο με το όνομά του -->
			   <!--			<b-form-group label="Επαφή"-->
			   <!--						  label-for="payment-project">-->
			   <!--			   <b-form-input disabled-->
			   <!--							 :value="`${user.name}  ${user.surname}`"-->
			   <!--							 placeholder="0.00,00" />-->
			   <!--			</b-form-group>-->
			   <!-- end::select calendar -->

			   <!-- start::select project -->
			   <validation-provider v-slot="validationContext"
									:rules="{ required: true}">
				  <b-form-group
					  :label="$t('generic.project')"
					  :state="getValidationState(validationContext)"
					  class="required"
					  label-for="project-category"
				  >
					 <v-select
						 v-if="id==='sidebar-payment-database-nav'"
						 v-model="pay.projectId"
						 :options="projectAll"
						 label="title"
						 placehoder="Επιλέξτε..."
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 </v-select>

					 <v-select
						 v-else
						 v-model="pay.projectId"
						 :options="projectInContact"
						 label="title"
						 placehoder="Επιλέξτε..."
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 </v-select>

				  </b-form-group>
				  <b-form-invalid-feedback> {{ $t(validationContext.errors[0]) }}</b-form-invalid-feedback>
			   </validation-provider>
			   <!-- end::select project -->


			   <!-- start::select contact -->
			   <validation-provider v-if="id==='sidebar-payment-database-nav'"
									v-slot="validationContext"
									:rules="{ required: true}">
				  <b-form-group
					  :label="$t('generic.client')"
					  :state="getValidationState(validationContext)"
					  class="required"
					  label-for="project-category"
				  >
					 <v-select
						 v-model="pay.contactId"
						 :options="allContact"
						 label="name"
						 placehoder="Επιλέξτε..."
					 >

						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>

						<template #option="{photo,color, name, surname }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname,name)"
							   :variant="getColor(color)"
							   size="26"
						   />
						   <span class="ml-50 align-middle">{{ surname }} {{ name }}</span>
						</template>

						<template #selected-option="{photo,color, name, surname }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname,name)"
							   :variant="getColor(color)"
							   size="26"
						   />
						   <span class="ml-50 align-middle">{{ surname }} {{ name }}</span>
						</template>

					 </v-select>
				  </b-form-group>
				  <b-form-invalid-feedback> {{ $t(validationContext.errors[0]) }}</b-form-invalid-feedback>
			   </validation-provider>
			   <!-- end::select contact -->
			   <!-- start::Date -->
			   <b-form-group
				   :label="$t('generic.date')"
				   label-for="Payment Date">
				  <date-picker
					  v-model="pay.date"
					  :show-time-header="true"
					  class="w-100"
					  format="DD-MM-YYYY"
					  type="date"
					  valueType="YYYY-MM-DD" />

				  <b-form-invalid-feedback>Validation</b-form-invalid-feedback>
			   </b-form-group>
			   <!-- end::Date -->

			   <!-- start::Payment Method -->
			   <b-form-group
				   :label="$t('generic.payment-method')"
				   label-for="payment-method"
			   >
				  <v-select
					  v-model="pay.status"
					  :options="$store.getters.getPayment"
					  :reduce="val => val.value"
					  input-id="payment-method"
					  label="text"

				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 <template #option="{ text }">
						<span> {{ text }}</span>
					 </template>
					 <template #selected-option="{ text }">
						<span> {{ text }}</span>
					 </template>
				  </v-select>
			   </b-form-group>
			   <!-- end::Payment Method -->

			   <!-- start::ammount -->
			   <b-form-group
				   :label="$t('generic.ammount')"
				   class="required"
				   label-for="payment-ammount">

				  <currency-input v-model="pay.price"
								  :options="{currencyDisplay: 'hidden', currency: 'EUR',locale:'de-DE',precision:2 }"
								  class="form-control" />


			   </b-form-group>
			   <!-- end::ammount -->

			   <!-- start::notes -->
			   <b-form-group
				   :label="$t('contacts.notes')"
				   label-for="payment-description">
				  <b-form-textarea id="payment-description"
								   v-model="pay.notes" />
			   </b-form-group>
			   <!-- end::notes -->

			   <!-- start::form actions -->
			   <div class="d-flex mt-2">
				  <b-button :disabled="invalid"
							class="mr-2"
							type="submit"
							variant="primary">
					 {{ $t('generic.add') }}


				  </b-button>
				  <b-button
					  variant="outline-secondary"
					  @click="hide">
					 {{ $t('generic.close') }}
				  </b-button>
			   </div>
			   <!-- end::form actions -->

			</b-form>
		 </validation-observer>

	  </template>
   </b-sidebar>
</template>

<script>
import {
   BAvatar,
   BButton,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormTextarea,
   BSidebar
} from 'bootstrap-vue'
import CurrencyInput from "@/views/components/Currency";
import vSelect from 'vue-select'
import FormMixin from "@/Mixins/FormMixin";
import ToastMixin from "@/Mixins/ToastMixin";
import {Money} from "v-money";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import _ from "lodash";
import {avatarText} from "@core/utils/filter";
import caldoSettings from "@/caldoSettings";


const guestsOptions = [
   {avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster'},
   {avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank'},
   {avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson'},
   {avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears'},
   {avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega'},
   {avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May'},
]

export default {
   components: {
	  BSidebar,
	  BForm,
	  Money,
	  BFormGroup,
	  CurrencyInput,
	  BFormInput,
	  BFormInvalidFeedback,
	  BFormCheckbox,
	  BAvatar,
	  BFormTextarea,
	  BButton,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider,
   },
   props: {
	  paymentEdit: Object,
	  allProjects: Array,
	  allCategories: Array,
	  projectInContact: Array,
	  pay: Object,
	  id: {
		 type: String,
		 default: 'sidebar-payment-database'
	  }
   },
   mixins: [FormMixin, ToastMixin],
   watch: {
	  'pay.projectId': {
		 immediate: true,
		 handler(val) {

			if (val) {
			   this.allContact = [_.find(this.allCategories, {id: val.contactId})]
			} else {
			   this.allContact = this.$store.getters.getAllContacts
			}
		 },
		 deep: true
	  },
	  'allProjects': {
		 handler(val) {
			this.projectAll = this.allProjects
			this.projectAllInContact = _.find(this.$store.getters.getAllContacts, {id: parseInt(this.$route.params.userId)})?.projects
		 }
	  },
	  'allCategories': {
		 handler(val) {
			this.allContact = val
		 }
	  },
	  'projectInContact': {
		 handler(val) {
			this.projectInContact = val
		 }
	  },
	  'pay.contactId': {
		 immediate: true,
		 handler(val) {
			if (val) {
			   this.projectAll = val.projects
			} else {
			   setTimeout(() => {
				  this.projectAll = this.allProjects
			   }, 1800)
			}

		 },
		 deep: true
	  },
   },

   data() {

	  return {
		 allProjectAll: [],
		 projectAll: [],
		 projectAllInContact: [],
		 allContact: [],
		 money: {
			decimal: ',',
			thousands: '.',
			prefix: '',
			suffix: '',
			precision: 2,
			masked: false
		 },
		 user: {
			name: null,
			surname: null,

		 },
		 contact: [],
		 guestsOptions: guestsOptions,
		 selectedPaymentMethod: ['Μετρητά'],
	  }
   },
   methods: {
	  closeModal() {
		 this.$root.$emit('bv::toggle::collapse', this.id)
	  },
	  fullName(name, surname) {
		 return avatarText(name, surname)
	  },
	  getColor(color) {
		 return caldoSettings.getColor(color)
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 if (dirty)
			return dirty || validated ? valid : null;
	  },
	  onSubmitPayments() {


		 this.$emit('submitPay', this.pay)
		 this.$refs.observer.reset();

		 // this.$emit('getPay', this.pay);


	  },
   },


   computed: {
	  ...mapGetters(["getContact"]),
	  calendars() {
		 return this.$store.getters.getProject.title
	  },
	  getProject() {
		 if (this.id === 'sidebar-payment-database') {
			return this.$store.getters.getContact.projects
		 } else {
			return this.allProjects
		 }
	  },
	  getContact() {

		 if (this.id === 'sidebar-payment-database') {
			return this.$store.getters.getContact.projects
		 } else {
			return this.allProjects
		 }
	  }


   },
   async created() {


	  if (this.$route.params.projectId)
		 await this.$store.dispatch("fetchProject", this.$route.params.projectId)
	  await this.$store.dispatch('fetchAllContacts')
	  this.allContact = this.$store.getters.getAllContacts


	  // axiosIns.get(`/project`, {
	  //  params: {
	  // 	rpp: 100
	  //  }
	  // }).then(r => {
	  //  this.allProjectAll = r.data.data
	  //  this.projectAll = r.data.data
	  // })

	  // // await this.$store.dispatch("fetchContact", this.$store.getters.getProject.contactId)
	  // let userData = await this.$store.getters.getContact
	  // this.user.name = userData.name;
	  // this.user.surname = userData.surname;


   }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<template>
   <b-sidebar
	   :id="id"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div
			 class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
		 >
			<h5 class="mb-0">
			   {{ title }}
			</h5>
			<div>
			   <feather-icon
				   class="ml-1 cursor-pointer"
				   icon="XIcon"
				   size="16"
				   @click.prevent="closeModal"
			   />
			</div>
		 </div>
		 <validation-observer ref="observer"
							  v-slot="{ handleSubmit, invalid }">
			<b-form class="p-2"
					@keyup="$emit('dirty')"
					@submit.prevent="onSubmit">
			   <!-- start::title -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: true }"
			   >
				  <b-form-group
					  :label="$t('generic.title')"
					  class="required"
					  label-for="project-title"
				  >
					 <b-form-input
						 id="project-title"
						 v-model="form.title"
						 :state="getValidationState(validationContext)"
						 placeholder=""
					 />
					 <b-form-invalid-feedback v-if="validationContext.errors[0]">
						{{ $t(getError(validationContext.errors[0])) }}
					 </b-form-invalid-feedback>
				  </b-form-group>
			   </validation-provider>
			   <!-- end::title -->

			   <!-- start::select contact -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: true }"
			   >
				  <b-form-group
					  :label="$t('generic.client')"
					  :state="getValidationState(validationContext)"
					  class="required"
					  label-for="mail-recepient"
				  >
					 <v-select
						 v-model="form.contactId"
						 :filter-by="myFilter"
						 :options="allCategories"
						 :reduce="(item) => item.id"
						 placehoder="Επιλέξτε..."
						 return-object
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						<template #option="{ photo, color, name, surname }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname, name)"
							   :variant="getColor(color)"
							   size="26"
						   />
						   {{ surname }} {{ name }}
						</template>

						<template #selected-option="{ photo, color, name, surname }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname, name)"
							   :variant="getColor(color)"
							   size="26"
						   />
						   {{ name }} {{ surname }}
						</template>
					 </v-select>
				  </b-form-group>
				  <b-form-invalid-feedback style="margin-top: -5px">
					 {{ validationContext.errors[0] }}
				  </b-form-invalid-feedback>
			   </validation-provider>
			   <!-- end::select contact -->

			   <!-- start::project category -->
			   <!-- Τις φέρνει από τις Ρυθμίσεις -->
			   <b-form-group
				   :label="$t('generic.category')"
				   label-for="project-category"
			   >
				  <v-select
					  v-model="form.categoryId"
					  :options="allProjects"
					  :reduce="(item) => item.id"
					  label="title"
					  placehoder="Επιλέξτε..."
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			   <!-- end::project category -->

			   <!-- start::project status -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: true }"
			   >
				  <b-form-group
					  :label="$t('generic.status')"
					  :state="getValidationState(validationContext)"
					  label-for="project-status"
				  >
					 <v-select
						 v-model="form.status"
						 :options="calendars"
						 :reduce="(item) => item.label"
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						<template #option="{ color, label }">
						   <div
							   :class="`bg-${color}`"
							   class="rounded-circle d-inline-block mr-50"
							   style="height: 10px; width: 10px"
						   />
						   <span> {{ label }}</span>
						</template>

						<template #selected-option="{ color, label }">
						   <div
							   :class="`bg-${color}`"
							   class="rounded-circle d-inline-block mr-50"
							   style="height: 10px; width: 10px"
						   />
						   <span> {{ label }}</span>
						</template>
					 </v-select>
				  </b-form-group>
				  <b-form-invalid-feedback class="mb-2">
					 {{ validationContext.errors[0] }}
				  </b-form-invalid-feedback>
			   </validation-provider>
			   <!-- end::project status -->

			   <b-row>
				  <b-col md="6">
					 <!-- start::project-start-date -->
					 <!--					 <validation-provider :rules="{ required: true }"-->
					 <!--										  v-slot="validationContext">-->
					 <b-form-group
						 :label="$t('generic.start-date-short')"
						 label-for="start"
					 >
						<date-picker
							v-model="form.startDate"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY HH:mm"
							placeholder="ΗΗ-ΜΜ-ΕΕΕΕ Ω:λ"
							type="datetime"
							valueType="YYYY-MM-DD HH:mm" />


						<!--						   <b-form-invalid-feedback class="mb-2">-->
						<!--							  {{ validationContext.errors[0] }}-->
						<!--						   </b-form-invalid-feedback>-->
					 </b-form-group>
					 <!--					 </validation-provider>-->
					 <!-- end::project-start-date -->
				  </b-col>
				  <b-col md="6">
					 <!-- start::project-Deadline -->
					 <b-form-group label="Deadline"
								   label-for="birth-date">
						<date-picker
							v-model="form.deadline"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY HH:mm"
							placeholder="ΗΗ-ΜΜ-ΕΕΕΕ Ω:λ"
							type="datetime"
							valueType="YYYY-MM-DD HH:mm" />

					 </b-form-group>
					 <!-- end::project-Deadline -->
				  </b-col>
			   </b-row>

			   <!-- start::form actions -->
			   <div
				   v-if="!$route.params.hasOwnProperty('projectId')"
				   class="d-flex mt-2"
			   >
				  <b-button
					  :disabled="!form.title || !form.contactId || !form.status"
					  class="mr-2"
					  type="submit"
					  variant="primary"
				  >
					 {{ $t("generic.save") }}
				  </b-button>
				  <b-button type="reset"
							variant="outline-secondary"
							@click="hide">
					 {{ $t("generic.close") }}
				  </b-button>
			   </div>
			   <div v-else
					class="d-flex mt-2">
				  <b-button class="mr-2"
							variant="primary"
							@click="$emit('submit')">
					 {{ $t("generic.save") }}
				  </b-button>
				  <b-button
					  variant="outline-secondary"
					  @click="hide">
					 {{ $t("generic.close") }}
				  </b-button>
			   </div>

			   <!-- end::form actions -->
			</b-form>
		 </validation-observer>
	  </template>
   </b-sidebar>
</template>

<script>
import {
   BAvatar,
   BButton,
   BCol,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BRow,
   BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import {avatarText} from "@core/utils/filter";
import caldoSettings from "@/caldoSettings";
import {Greek} from "flatpickr/dist/l10n/gr.js";

extend("required", {
   ...required,
   message: "Υποχρεωτικό πεδίο",
});

export default {
   props: {
	  isDisabled: Boolean,
	  form: Object,
	  allProjects: Array | Object,
	  allCategories: Array,
	  title: String,
	  id: {
		 type: String,
		 default: "sidebar-project",
	  },
   },
   watch: {
	  form: {
		 deep: true,
		 handler() {


		 },
	  },
   },
   components: {
	  BRow,
	  BCol,
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BFormCheckbox,
	  BButton,
	  flatPickr,
	  BAvatar,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider,
   },
   data() {
	  return {
		 config: {
			allowInput: true,
			locale: Greek,
			enableTime: true,
			altInput: true,
			time_24hr: true,
			altFormat: "d-m-Y H:i",
			dateFormat: "Y-m-d H:i",
		 },
		 formValue: this.form,
		 selectedProjectCategories: {title: "Web"},
		 projectCategories: [
			{title: "Web"},
			{title: "Printworks"},
			{title: "Digital"},
		 ],
	  };
   },
   methods: {
	  closeModal() {
		 this.$root.$emit('bv::toggle::collapse', this.id)
	  },
	  myFilter: (option, label, search) => {
		 let temp = search.toLowerCase();
		 return option.name.toLowerCase().indexOf(temp) > -1 ||
			 option.surname.toLowerCase().indexOf(temp) > -1
	  },
	  fullName(name, surname) {
		 return avatarText(name, surname);
	  },
	  getColor(color) {
		 return caldoSettings.getColor(color);
	  },
	  onSubmit() {
		 console.log(this.id)
		 this.$emit("submit", this.form);
		 this.$root.$emit('bv::hide::collapse', this.id)
		 this.$refs.observer.reset();
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 return validated ? valid : null;
	  },
	  getError(error) {
		 return `errors.${error
			 .toLowerCase()
			 .replaceAll(" ", "-")
			 .replace(".", "")}`;
	  },
   },
   computed: {
	  calendars() {
		 return this.$store.getters.getCalendars;
	  },
   },
   async created() {
	  // const responseCategory = await axiosIns.get(`/contact-category`, {
	  //  params: {
	  // 	rpp: 100
	  //  }
	  // })
	  // this.allCategories = responseCategory.data.data
   },
};
</script>

<style lang="scss"
	   scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


.assignee-selector {
   ::v-deep .vs__dropdown-toggle {
	  padding-left: 0;
   }
}
</style>
export default [
    {
        header: 'Apps'
    },
    {
        title: 'Πελάτες',
        route: 'contacts',
        icon: 'UserIcon',
        id:'tour-clients',
    },
    {
        title: 'Έργα',
        route: 'projects',
        icon: 'ClipboardIcon',
        id:'tour-projects',
    },
    {
        title: 'Ημερολόγιο',
        route: 'calendar',
        icon: 'CalendarIcon',
    },
    {
        title: 'Έξοδα',
        route: 'expences',
        icon: 'ShoppingBagIcon',
    },
    {
        title: 'Todo',
        route: 'todo',
        icon: 'CheckSquareIcon',
    },
    
]


<template>
   <b-sidebar
	   :id="id"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div
			 class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
		 >
			<h5 class="mb-0">{{ title }}</h5>
			<feather-icon class="ml-1 cursor-pointer"
						  icon="XIcon"
						  @click.prevent="closeModal"
						  size="16" />
		 </div>
		 <validation-observer ref="observer"
							  v-slot="{ handleSubmit, invalid }">
			<b-form class="p-2"
					@submit.prevent="handleSubmit(onSubmit)">
			   <!-- start::title -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: true }"
			   >
				  <b-form-group
					  :label="$t('generic.title')"
					  class="required"
					  label-for="expence-title"
				  >
					 <b-form-input
						 id="expence-title"
						 v-model="form.title"
						 :state="getValidationState(validationContext)"
						 placeholder=""
					 />
					 <b-form-invalid-feedback v-if="validationContext.errors[0]">
						{{ $t(getError(validationContext.errors[0])) }}
					 </b-form-invalid-feedback>
				  </b-form-group>
			   </validation-provider>
			   <!-- end::title -->

			   <b-row>
				  <b-col md="6">
					 <validation-provider
						 v-slot="validationContext"
						 :rules="{ required: true }"
					 >
						<!-- start::ammount -->
						<b-form-group
							:label="$t('generic.ammount')"
							label-for="expence-ammount"
						>
						   <currency-input
							   ref="amountRef"
							   v-model="form.amount"
							   :options="{
                      currencyDisplay: 'hidden',
                      currency: 'EUR',
                      locale: 'de-DE',
                      precision: 2,
                    }"
							   :state="getValidationState(validationContext)"
							   class="form-control"
						   />
						   <b-form-invalid-feedback v-if="validationContext.errors[0]">
							  {{ $t(getError(validationContext.errors[0])) }}
						   </b-form-invalid-feedback>
						</b-form-group>
					 </validation-provider>
					 <!-- end::ammount -->
				  </b-col>
				  <b-col md="6">
					 <!-- start::expence-date -->

					 <b-form-group
						 :label="$t('generic.date')"
						 label-for="expence-date"
					 >

						<date-picker
							v-model="form.datestamp"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY"
							type="date"
							valueType="YYYY-MM-DD" />
						<span v-if="!form.datestamp"
							  class="invalid-feedback"
						>Υποχρεωτικό πεδίο</span
						>
					 </b-form-group>

					 <!-- end::expence-date -->
				  </b-col>
			   </b-row>

			   <!-- start::expence-repeating -->
			   <b-form-group>
				  <b-form-checkbox v-model="form.repeatable">
					 {{ $t("generic.repeating") }}
				  </b-form-checkbox>
			   </b-form-group>
			   <!-- end::expence-repeating -->

			   <!-- start::expence-end-date -->
			   <div v-if="form.repeatable"
					class="bg-light p-1 rounded mb-1">
				  <validation-provider
					  v-slot="validationContext"
					  rules="required_if:form.repeatable,true"
				  >
					 <b-form-group :label="$t('generic.repeat-each')">
						<b-form-radio-group
							v-model="form.interval"
							:options="interval"
							:state="getValidationState(validationContext)"
						/>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <b-form-group
					  :label="$t('generic.until')"
					  label-for="expence-end-date"
				  >
					 <date-picker
						 v-model="form.repeatableUntil"
						 :show-time-header="true"
						 class="w-100"
						 format="DD-MM-YYYY"
						 type="date"
						 valueType="YYYY-MM-DD" />
				  </b-form-group>
			   </div>
			   <!-- end::expence-end-date -->

			   <!-- start::expence category -->
			   <!-- Τις φέρνει από τις Ρυθμίσεις -->
			   <b-form-group
				   :label="$t('contacts.category')"
				   label-for="expence-category"
			   >
				  <v-select
					  v-model="form.categoryId"
					  :options="$store.getters.getSettingExpense"
					  :reduce="(item) => item.id"
					  label="title"
					  placehoder="Επιλέξτε..."
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			   <!-- end::expence category -->


			   <!-- start::expence project -->

			   <b-form-group
				   :label="$t('generic.project')"
				   label-for="expence-category"
			   >
				  <v-select
					  v-model="form.projectId"
					  :options="$store.getters.getCategoriesProject"
					  :reduce="(item) => item.id"
					  label="title"
					  placehoder="Επιλέξτε..."
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			   <!-- end::expence project -->



			   <!-- start::select payment method -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: false }"
			   >
				  <b-form-group
					  :label="$t('generic.payment-method')"
					  label-for="payment-method"
				  >
					 <v-select
						 v-model="form.paymentMethod"
						 :options="getPayment"
						 :reduce="(val) => val.value"
						 :state="getValidationState(validationContext)"
						 label="text"
						 placehoder="Επιλέξτε..."
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 </v-select>
					 <b-form-invalid-feedback>
						{{ validationContext.errors[0] }}
					 </b-form-invalid-feedback>
				  </b-form-group>
			   </validation-provider>
			   <!-- end::select payment method -->

			   <!-- start:notes -->
			   <b-form-group :label="$t('contacts.notes')"
							 label-for="expence-notes">
				  <b-form-textarea
					  v-model="form.notes"
					  :options="editorOption"
				  />
				  <div
					  id="quill-toolbar"
					  class="d-flex justify-content-end border-top-0"
				  ></div>
			   </b-form-group>
			   <!-- end:notes -->

			   <!-- start::form actions -->
			   <div class="d-flex">
				  <b-button
					  :disabled="invalid || !form.datestamp"
					  class="mr-2"
					  type="submit"
					  variant="primary"
				  >
					 {{ $t("generic.save") }}
				  </b-button>
				  <b-button
					  variant="outline-secondary"
					  @click="hide">
					 {{ $t("generic.cancel1") }}
				  </b-button>
			   </div>
			   <!-- end::form actions -->
			</b-form>
		 </validation-observer>
	  </template>
   </b-sidebar>
</template>

<script>
import {
   BAvatar,
   BButton,
   BCol,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormRadioGroup, BFormTextarea,
   BRow,
   BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required, required_if} from "vee-validate/dist/rules";
import {quillEditor} from "vue-quill-editor";
import caldoSettings from "@/caldoSettings";
import store from "@/store";
import expenses from "@/store/expenses";
import CurrencyInput from "@/views/components/Currency";
import FormMixin from "@/Mixins/FormMixin";
import moment from "moment";
import ToastMixin from "@/Mixins/ToastMixin";

extend("required", {
   ...required,
   message: "Υποχρεωτικό πεδίο",
});
extend("required_if", {
   ...required_if,
   message: "Υποχρεωτικό πεδίο",
});

export default {
   props: {
	  expenses: Object,
	  title: String,
	  id: {
		 type: String,
		 default: "add-expence",
	  },
   },
   components: {
	  BRow,
	  BCol,
	  CurrencyInput,
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BAvatar,
	  BFormInput,
	  BButton,
	  BFormRadioGroup,
	  BFormTextarea,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider,
	  BFormCheckbox,
	  quillEditor,
   },
   mixins: [FormMixin, ToastMixin],
   data() {
	  return {
		 form: {
			title: expenses.title,
			amount: false,
			datestamp: new Date(),
			repeatable: false,
			interval: "week",
			repeatableUntil: new Date(),
			categoryId: null,
			projectId: null,
			paymentMethod: null,
			notes: null,
			reference:expenses.reference
		 },
		 // formValue: this.form,
		 editorOption: {
			modules: {},
			placeholder: this.$i18n.t('generic.insert-text-here'),
		 },
		 selectedProjectCategories: {title: "Web"},
		 projectCategories: [
			{title: "Web"},
			{title: "Printworks"},
			{title: "Digital"},
		 ],
	  };
   },
   watch: {
	  expenses: {
		 deep: true,
		 handler() {
			if (!this.expenses) {
			   this.resetForm();
			} else {
			   this.form = this.expenses;

			   if (this.form.repeatable === 1) {
				  this.form.repeatable = true;
			   }
			}
		 },
	  },

	  form: {
		 deep: true,
		 handler() {
			if (this.form.amount == null) {
			   this.$refs.amountRef.$el.classList.add("is-invalid");
			} else {
			   this.$refs.amountRef.$el.classList.remove("is-invalid");
			}
			// if (this.form.datestamp == "") {
			//    document
			// 	   .querySelector(".flatpickr-input")
			// 	   .nextElementSibling.classList.add("is-invalid");
			// } else {
			//    document
			// 	   .querySelector(".flatpickr-input")
			// 	   .nextElementSibling.classList.remove("is-invalid");
			// }
		 },
	  },
   },

   methods: {
	  closeModal() {
		 this.$root.$emit('bv::toggle::collapse', this.id)
	  },
	  async onSubmit() {
		 if (this.form.categoryId === null) {
			delete this.form.categoryId;
		 }
		 if (this.form.datestamp === null) {
			delete this.form.datestamp;
		 }
		 if (this.form.repeatable == false) {
			this.form.interval = "week";
			this.form.repeatableUntil = null;
		 }
		 if (this.form.datestamp) {
			this.form.datestamp = moment(this.form.datestamp).format("YYYY-MM-DD");
		 }
		 if (this.form.repeatableUntil) {
			this.form.repeatableUntil = moment(this.form.repeatableUntil).format(
				"YYYY-MM-DD"
			);
		 }
		 if (this.form.reference == null) {
			this.form.reference = moment().valueOf().toString().substring(5, 10)
		 }


		 console.log(this.form);
		 await store.dispatch("storeExpensesesCategory", this.form);
		 this.notify(`Δημιουργήθηκε`);
		 this.$root.$emit("bv::toggle::collapse", this.id);
		 this.$refs.observer.reset();
		 this.resetForm();
		 this.$emit("key");
	  },

	  resetForm() {
		 this.form = {
			title: null,
			amount: false,
			datestamp: new Date(),
			repeatable: false,
			interval: "week",
			repeatableUntil: new Date(),
			categoryId: null,
			paymentMethod: null,
			notes: null,
		 };
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 return validated ? valid : null;
	  },
	  getError(error) {
		 return `errors.${error
			 .toLowerCase()
			 .replaceAll(" ", "-")
			 .replace(".", "")}`;
	  },
   },
   computed: {
	  calendars() {
		 return this.$store.getters.getCalendars;
	  },
	  interval() {
		 return caldoSettings.intervalOptions;
	  },
	  getPayment() {
		 return [
			{text: 'Μετρητά', value: 'cash'},
			{text: 'Κατάθεση σε τράπεζα', value: 'Κατάθεση σε τράπεζα'},
			{text: 'Κάρτα', value: 'card'},
			{text: 'Paypal', value: 'paypal'},
		 ]
	  },
   },
   async created() {
	  await this.$store.dispatch("fetchProjectSetting");
   },
};
</script>

<style lang="scss"
	   scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "@core/scss/vue/libs/quill.scss";
</style>
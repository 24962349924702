import underlineIcon from "vue-feather-icons/icons/UnderlineIcon";
import moment from "moment";

export default {
    methods: {
        revertCurrency(index) {
            this.form.services[index].price = this.parseLocaleNumber(this.form.services[index].price, 'de');
        },
        convertCurrency(index) {
            if(this.form.services[index].price === undefined){
                this.form.services[index].price =0
            }
            this.form.services[index].price = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: "code"
            }).format(this.form.services[index].price)
                .replace("EUR", "")
                .trim();

        },
        parseLocaleNumber(stringNumber, locale) {
            var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
            var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

            return parseFloat(stringNumber
                .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
                .replace(new RegExp('\\' + decimalSeparator), '.')
            );
        },
        calculatePrice(index) {

            if (!index) {
                return '0,00';
            }
            let currency = this.parseLocaleNumber(index, 'de');


            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: "code"
            }).format(currency)
                .replace("EUR", "")
                .trim();


        },
        convertFormatPrice(currency) {



            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: "code"
            }).format(currency)
                .replace("EUR", "")
                .trim();
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    }
}
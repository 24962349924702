export default [
    {
        header: 'ΛΟΙΠΑ',
    },
    {
        title: 'Αναφορές',
        route: 'reports',
        icon: 'PieChartIcon',
        children: [
            {
                title: 'Καρτέλα πελάτη',
                route: { name: 'reports-transactions' },
            },
            {
                title: 'Υπόλοιπα πελατών',
                route: { name: 'reports-balance' },
            },
            {
                title: 'Λίστα πελατών',
                route: { name: 'clients-list' },
            },
            {
                title: 'Λίστα έργων',
                route: { name: 'projects-list' },
            },
            {
                title: 'Λίστα εργασιών',
                route: { name: 'tasks-list' },
            },
            {
                title: 'Λίστα εξόδων',
                route: { name: 'expences-list' },
            },
            {
                title: 'Έσοδα ανά υπηρεσία',
                route: { name: 'income-list' },
            },
        ],
    }
]
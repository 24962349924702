<template>
   <b-nav-item-dropdown
	   @hide="handlerReadNotification"
	   ref="button"
	   class="dropdown-notification mr-25"
	   menu-class="dropdown-menu-media"
	   right
   >
	  <template #button-content>
		 <feather-icon
			 :badge="countNotification"
			 badge-classes="bg-danger"
			 class="text-body"
			 icon="BellIcon"
			 size="21"
		 />
	  </template>

	  <!-- Header -->
	  <li class="dropdown-menu-header">
		 <div class="dropdown-header d-flex">
			<h4 class="notification-title mb-0 mr-auto">
			   {{ $t('notifications.notifications') }}
			</h4>
			<b-badge
				pill
				variant="light-primary"
			>
			   {{ countNotification }} {{ $t('generic.new-3') }}
			</b-badge>
		 </div>
	  </li>

	  <!-- Notifications -->
	  <vue-perfect-scrollbar
		  :settings="perfectScrollbarSettings"
		  class="scrollable-container media-list scroll-area"
		  tagname="li"
	  >
		 <!-- Account Notification -->
		 <div
			 v-if="notifications.length"
		 >
			<b-link


				v-for="notification in notifications"
				:key="notification.hash"
				class=""
			>
			   <b-media :class=" notification.notification_read ? 'opacity-80':''">
				  <template #aside>
					 <b-avatar
						 size="32"
						 :variant="notification.type"
					 >
						<feather-icon :icon="notification.icon" />
					 </b-avatar>
				  </template>
				  <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
				  </p>
				  <p class="d-flex   m-0">
					 <small class="m-0 notification-text">{{ notification.subtitle }}</small>
					 <span style="margin:0 5px">-</span>
					 <small class="m-0 notification-text">
						{{ dateConvert(notification.expiryDate) }}
					 </small>
				  </p>
			   </b-media>
			</b-link>
		 </div>
		 <div v-else>
			empty
		 </div>
	  </vue-perfect-scrollbar>

	  <!-- Cart Footer -->
	  <li class="dropdown-menu-footer">
		 <b-button
			 @click="hide"
			 :to="{ name: 'notifications'}"
			 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			 variant="primary"
			 block
		 >{{ $t('notifications.all-notifications') }}
		 </b-button>
	  </li>
   </b-nav-item-dropdown>
</template>

<script>
import {BAvatar, BBadge, BButton, BFormCheckbox, BLink, BMedia, BNavItemDropdown,} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from "@/store";
import moment from 'moment'
import _ from "lodash";
import caldoSettings from "@/caldoSettings";


export default {
   components: {
	  BNavItemDropdown,
	  BBadge,
	  BMedia,
	  BLink,
	  BAvatar,
	  VuePerfectScrollbar,
	  BButton,
	  BFormCheckbox,
   },
   directives: {
	  Ripple,
   },
   data() {
	  return {
		 notifications: [],
		 countNotification: 0

	  }
   },
   filters: {
	  moment: function (date) {
		 return moment(date).format('MMMM Do YYYY, HH:mm:ss a');
	  }
   },

   methods: {
	  dateConvert: function (date) {
		 return moment(date).format('DD-MM-YYYY');
	  },
	  hide() {

		 this.$refs.button.$el.classList.remove('show')
		 document.querySelector('.dropdown-menu-media').classList.remove('show')
	  },
	  async handlerReadNotification() {
		 let unreadNotification = this.notifications.filter(notification => notification.notification_read <= 0);

		 let hashs = _.map(unreadNotification, 'id');

		 if (hashs.length) {

			for await (const hash of hashs) {
			   await this.$store.dispatch('readNotification', hash)
			}

			await store.dispatch('fetchNotification')
		 }
	  },

   },

   watch: {
	  '$store.getters.getNotification'(val) {
		 this.notifications = []

		 val.forEach(el => {
			let url = ''
			if (el.type === 'project_task') {

			   console.log()

			}

			this.notifications.push(
				{
				   id: el.hash,
				   title: el.title,
				   subtitle: caldoSettings.getTypeNotification(el.type),
				   type: caldoSettings.getColorNotification(el.type),
				   icon: caldoSettings.getIconNotification(el.type),
				   expiryDate: el.expiryDate,
				   notification_read: el.notification_read,
				}
			)
		 })

		 let count = val.filter(el => el.notification_read === 0)
		 this.countNotification = count.length;
	  }
   },
   async created() {
	  await store.dispatch('fetchNotification')


   },
   setup() {


	  const systemNotifications = [
		 {
			title: 'Αναβάθμιση σε 1.1',
			subtitle: 'BEEZIO',
			type: 'light-success',
			icon: 'SettingsIcon',
		 },
		 {
			title: 'Αναβάθμιση σε 1.1',
			subtitle: 'd',
			type: 'light-success',
			icon: 'SettingsIcon',
		 },
	  ]

	  const perfectScrollbarSettings = {
		 maxScrollbarLength: 60,
		 wheelPropagation: false,
	  }

	  return {
		 // notifications,
		 systemNotifications,
		 perfectScrollbarSettings,
	  }
   },
}
</script>

<style>

.opacity-80 {
   opacity: 0.8 !important;
}


</style>

<template>
   <div>

	  <div @mouseout="drawerActive = false"
		   :class="{ 'show' : drawerActive }"
	  />

	  <b-navbar-nav class="nav">
		 <b-nav-item
			 v-for="(bookmark, index) in bookmarks"
			 :id="`bookmark-${index}`"
			 :key="index"
			 @click="onNav(bookmark.route)"
		 >
			<feather-icon
				:icon="bookmark.icon"
				size="21"
			/>
			<b-tooltip
				triggers="hover"
				:target="`bookmark-${index}`"
				:title="$t('generic.'+bookmark.title)"
				:delay="{ show: 1000, hide: 50 }"
			/>
		 </b-nav-item>
		 <!--		 <b-nav-item-dropdown-->
		 <!--			 link-classes="bookmark-star"-->
		 <!--			 lazy-->
		 <!--			 @hidden="resetsearchQuery"-->
		 <!--		 >-->
		 <!--			<feather-icon-->
		 <!--				slot="button-content"-->
		 <!--				icon="StarIcon"-->
		 <!--				size="21"-->
		 <!--				class="text-warning"-->
		 <!--			/>-->

		 <!--			&lt;!&ndash; Dropdown Content &ndash;&gt;-->
		 <!--			<li style="min-width:300px">-->
		 <!--			   <div class="p-1">-->
		 <!--				  <b-form-input-->
		 <!--					  id="boomark-search-input"-->
		 <!--					  v-model="searchQuery"-->
		 <!--					  placeholder="Explore Vuexy..."-->
		 <!--					  autofocus-->
		 <!--				  />-->
		 <!--			   </div>-->
		 <!--			   <vue-perfect-scrollbar-->
		 <!--				   :settings="perfectScrollbarSettings"-->
		 <!--				   class="search-list search-list-bookmark scroll-area"-->
		 <!--				   :class="{'show': filteredData.pages && filteredData.pages.length }"-->
		 <!--				   tagname="ul"-->
		 <!--			   >-->

		 <!--				  <b-dropdown-item-->
		 <!--					  v-for="(suggestion, index) in filteredData.pages || bookmarks"-->
		 <!--					  :key="index"-->
		 <!--					  class="suggestion-group-suggestion cursor-pointer"-->
		 <!--					  link-class="d-flex align-items-center"-->
		 <!--					  :to="suggestion.route"-->
		 <!--					  @mouseenter="currentSelected = index"-->
		 <!--				  >-->
		 <!--					 <feather-icon-->
		 <!--						 :icon="suggestion.icon"-->
		 <!--						 class="mr-75"-->
		 <!--						 size="18"-->
		 <!--					 />-->
		 <!--					 <span class="align-middle">{{ suggestion.title }}</span>-->
		 <!--					 <feather-icon-->
		 <!--						 icon="StarIcon"-->
		 <!--						 class="ml-auto"-->
		 <!--						 size="16"-->
		 <!--						 :class="{'text-warning': suggestion.isBookmarked}"-->
		 <!--						 @click.stop.prevent="toggleBookmarked(suggestion)"-->
		 <!--					 />-->

		 <!--				  </b-dropdown-item>-->
		 <!--				  <b-dropdown-item-->
		 <!--					  v-show="!(filteredData.pages && filteredData.pages.length) && searchQuery"-->
		 <!--					  disabled-->
		 <!--				  >-->
		 <!--					 No Results Found.-->
		 <!--				  </b-dropdown-item>-->
		 <!--			   </vue-perfect-scrollbar>-->
		 <!--			</li>-->
		 <!--		 </b-nav-item-dropdown>-->

	  </b-navbar-nav>

	  <add-contact id="sidebar-task-handler-nav"></add-contact>

	  <sidebar-project
		  :all-categories="allCategories"
		  :all-projects="$store.getters.getSettingProject"
		  id="sidebar-project-nav"
		  :form="form"
		  title="Νέο Έργο"
		  @submit="onSubmit" />

	  <add-expence title="Νέο Έξοδο"
				   id="add-expence-nav"
				   :expenses="expensesById"></add-expence>


	  <sidebar-payment-database :all-projects="allProject"
								:all-categories="allCategories"
								id="sidebar-payment-database-nav"
								:pay="pay"
								@submitPay="onSubmitPay" />

   </div>
</template>

<script>
import {BDropdownItem, BFormInput, BNavbarNav, BNavItem, BNavItemDropdown, BTooltip,} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import {ref, watch} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import searchAndBookmarkData from '../search-and-bookmark-data'
import AddContact from "@/views/caldo-global/AddContact";
import SidebarProject from "@/views/caldo-global/SidebarProject";
import ToastMixin from "@/Mixins/ToastMixin";
import FormMixin from "@/Mixins/FormMixin";
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import AddExpence from "@/views/caldo-global/AddExpence";
import SidebarPaymentDatabase from "@/views/caldo-global/SidebarPaymentDatabase";
import moment from "moment";

export default {
   components: {
	  BNavbarNav,
	  BNavItem,
	  BTooltip,
	  BNavItemDropdown,
	  BFormInput,
	  SidebarPaymentDatabase,
	  VuePerfectScrollbar,
	  BDropdownItem,
	  AddExpence,
	  AddContact,
	  SidebarProject
   },
   mixins: [ToastMixin, FormMixin, CurrencyMixin],
   data() {
	  return {
		 drawerActive: false,
		 allProject: [],
		 allCategories: [],
		 expensesById: null,
		 pay: {
			"reference": null,
			"contactId": this.$route.params.userId,
			"projectId": null,
			"status": null,
			"price": 0,
			"date": null,
			"notes": null,
			"discount": null,
			edit: false,
		 },
		 form: {
			"title": null,
			"contactId": null,
			"categoryId": null,
			"status": 'Σε εξέλιξη',
			"startDate": new Date().toISOString().split('T')[0],
			"deadline": null,
		 }
	  }
   },
   watch: {
	  '$store.getters.getCategoriesProject': {
		 immediate: true,
		 deep: true,
		 handler(val) {
			// update locally O relevant data
			this.allProject = val
		 }
	  },
	  '$store.getters.getAllContacts': {
		 immediate: true,
		 deep: true,
		 handler(val) {
			// update locally relevant data
			this.allCategories = val
		 }
	  }
   },
   methods: {
	  async onSubmit() {
		 try {
			await this.$store.dispatch("storeProjectCategory", this.removeEmpty(this.form));
			this.notify(`Δημιουργήθηκε`)
			this.$root.$emit('bv::toggle::collapse', 'sidebar-project-nav')
			this.resetField(this.form);
			this.form.status = 'Σε εξέλιξη';
			this.form.startDate = new Date().toISOString().split('T')[0]
			await this.$store.dispatch("fetchProjectCategories",  {
			   params: {
				  page: 1,
				  rpp: 6,
				  orderBy: ['id'],
				  sortBy: 'desc',
			   },
			});
		 } catch (e) {
		 }
	  },
	  async onSubmitPay() {


		 const form = {

			"projectId": this.pay.projectId.id,
			"contactId": this.pay.contactId.id,
			"status": this.pay.status,
			"price": this.pay.price,
			"date": this.pay.date,
			"notes": this.pay.notes,
			"reference": moment().valueOf().toString().substring(5, 10)
		 }

		 try {
			await this.$store.dispatch("storePaymentCategory", this.removeEmpty(form));

			this.$root.$emit('bv::toggle::collapse', 'sidebar-payment-database-nav')
			this.resetField(this.pay);

			this.notify(`Δημιουργήθηκε`)

		 } catch (e) {

		 }

	  },
	  async getById(id) {
		 this.title = 'Επεξεργασία';
		 await this.$store.dispatch('fetchExpensesesById', id);
		 this.expensesById = this.$store.getters.getByIdExpense;
	  },
	  async onNav(route) {
		 if (route.name === 'client') {
			this.$root.$emit('bv::toggle::collapse', 'sidebar-task-handler-nav')
		 }
		 if (route.name === 'project') {
			this.$root.$emit('bv::toggle::collapse', 'sidebar-project-nav')
		 }
		 if (route.name === 'expenses') {
			this.$root.$emit('bv::toggle::collapse', 'add-expence-nav')
		 }
		 if (route.name === 'payment') {
			this.$root.$emit('bv::toggle::collapse', 'sidebar-payment-database-nav')
		 }
	  }
   },
   async created() {
	  await this.$store.dispatch('fetchExpenseSetting');
	  // await this.$store.dispatch('fetchAllContacts');


	  await this.$store.dispatch("fetchAllCategoriesProject", {
		 params: {
			rpp: 999
		 }
	  });
   },

   setup() {
	  const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
	  const bookmarks = ref(searchAndBookmarkData.pages.data.filter(page => page.isBookmarked))
	  const currentSelected = ref(-1)


	  const perfectScrollbarSettings = {
		 maxScrollbarLength: 60,
	  }

	  const {
		 searchQuery,
		 resetsearchQuery,
		 filteredData,
	  } = useAutoSuggest({data: {pages: searchAndBookmarkDataPages.value}, searchLimit: 6})

	  watch(searchQuery, val => {
		 store.commit('app/TOGGLE_OVERLAY', Boolean(val))
	  })

	  watch(filteredData, val => {
		 currentSelected.value = val.pages && !val.pages.length ? -1 : 0
	  })

	  const suggestionSelected = () => {
		 const suggestion = filteredData.value.pages[currentSelected.value]
		 router.push(suggestion.route).catch(() => {
		 })
		 resetsearchQuery()
	  }


	  const toggleBookmarked = item => {
		 // Find Index of item/page in bookmarks' array
		 const pageIndexInBookmarks = bookmarks.value.findIndex(i => i.route === item.route)

		 // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
		 // Else => Item is not bookmarked => Add item to bookmarks' array
		 if (pageIndexInBookmarks > -1) {
			bookmarks.value[pageIndexInBookmarks].isBookmarked = false
			bookmarks.value.splice(pageIndexInBookmarks, 1)
		 } else {
			bookmarks.value.push(item)
			bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
		 }
	  }

	  return {
		 bookmarks,
		 perfectScrollbarSettings,
		 currentSelected,
		 suggestionSelected,
		 toggleBookmarked,
		 // AutoSuggest
		 searchQuery,
		 resetsearchQuery,
		 filteredData,
	  }
   },
}
</script>

<style lang="scss"
	   scoped>
@import '~@core/scss/base/bootstrap-extended/include';


ul {
   list-style: none;
   padding: 0;
   margin: 0;
}


p {
   margin: 0;
}


.z-i {
   z-index: -10 !important;
}


.nav-bookmar-content-overlay {
   position: fixed;
   opacity: 0;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.5);
   -webkit-transition: all 0.7s;
   transition: all 0.7s;
   z-index: -1;

   &:not(.show) {
	  pointer-events: none;
   }

   &.show {
	  cursor: pointer;
	  z-index: 10;
	  opacity: 1;
   }
}
</style>

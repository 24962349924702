<template>
   <!-- start::user dropdown -->
   <b-nav-item-dropdown right
						toggle-class="d-flex align-items-center dropdown-user-link"
						class="dropdown-user">
	  <template #button-content>
		 <div class="d-sm-flex d-none user-nav">
			<p class="user-name font-weight-bolder mb-0">
<!--			   -->
			   {{ $store.getters.getUserLogged.name }} {{charAt }}.
			</p>
			<span class="user-status">{{ $t('generic.more') }}</span>
		 </div>
		 <b-avatar size="40"
				   :src="$store.getters.getUserLogged.photo"
				   variant="light-primary"
				   badge
				   class="badge-minimal"
				   badge-variant="success">
			<!-- start:: αν δεν έχει avatar ο χρήστης -->
			<feather-icon v-if="!$store.getters.getUserLogged.photo"
						  icon="UserIcon"
						  size="22" />
			<!-- end:: αν δεν έχει avatar ο χρήστης -->
		 </b-avatar>
	  </template>

	  <b-dropdown-item :to="{ name: 'settings'}"
					   link-class="d-flex align-items-center">
		 <feather-icon size="16"
					   icon="UserIcon"
					   class="mr-50" />
		 <span>Τα στοιχεία μου</span>
	  </b-dropdown-item>

	  <b-dropdown-divider />

	  <b-dropdown-item link-class="d-flex align-items-center"
					   @click="logout">
		 <feather-icon size="16"
					   icon="LogOutIcon"
					   class="mr-50" />
		 <span>Αποσύνδεση</span>
	  </b-dropdown-item>

   </b-nav-item-dropdown>
   <!-- end::user dropdown -->
</template>

<script>
import {
   BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import {initialAbility} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import {mapGetters} from 'vuex'
import axiosIns from "@/libs/axios";

export default {
   components: {
	  BNavItemDropdown,
	  BDropdownItem,
	  BDropdownDivider,
	  BAvatar,
   },
   data() {
	  return {
		 userName: '',
		 userSurname: '',
		 userPhoto: '',
	  }
   },
   computed: {
	  ...mapGetters(['getUserLogged']),
	  user() {
		 return this.getUserLogged;
	  },

	  charAt(){
		 return this.$store.getters.getUserLogged.surname?.charAt(0).toUpperCase();
	  }

   },
   methods: {
	  logout() {
		 this.$tours['myTour']?.stop()
		 // Remove userData from localStorage
		 // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
		 localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
		 localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

		 // Remove userData from localStorage
		 localStorage.removeItem('userData')


		 this.$router.replace({name: 'auth-login'})
	  },
   },

   async created() {
	  await this.$store.dispatch("fetchLoggedUser")
   }
}
</script>

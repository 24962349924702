<template>
   <b-sidebar
	   :id="id"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <!-- start::header -->
		 <div
			 class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
		 >
			<h5 class="mb-0">
			   {{ $t("generic.new-1") }} {{ $t("generic.client") }}
			</h5>
			<!-- start::action icons -->
			<div>
			   <feather-icon
				   class="ml-1 cursor-pointer"
				   icon="XIcon"
				   size="16"
				   @click.prevent="closeModal"
			   />
			</div>
			<!-- end::action items -->
		 </div>
		 <!-- end::header -->

		 <!-- start::body -->
		 <div class="p-2">
			<validation-observer ref="observer"
								 v-slot="{ handleSubmit, invalid }">
			   <b-form ref="form">

				  <!-- start::lastName -->
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ required: true }"
				  >
					 <b-form-group
						 :label="$t('contacts.surname')"
						 class="required"
						 label-for="lastName"
					 >
						<b-input-group
							:class="
                    validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
							class="input-group-merge"
						>
						   <b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
						   </b-input-group-prepend>
						   <b-form-input
							   id="lastName"
							   v-model="form.surname"
							   :state="getValidationState(validationContext)"
							   placeholder="Doe"
							   trim
						   />
						</b-input-group>
						<b-form-invalid-feedback>
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback
						>
					 </b-form-group>
				  </validation-provider>
				  <!-- end::lastName -->

				  <!-- start::firstName -->
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ required: true }"
				  >
					 <b-form-group
						 :label="$t('contacts.name')"
						 class="required"
						 label-for="firstName"
					 >
						<b-input-group
							:class="
                    validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
							class="input-group-merge"
						>
						   <b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
						   </b-input-group-prepend>
						   <b-form-input
							   id="firstName"
							   v-model="form.name"
							   :state="getValidationState(validationContext)"
							   autofocus
							   placeholder="John"
							   trim
						   />
						</b-input-group>
						<b-form-invalid-feedback id="form-name-lice-feedback">
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
				  <!-- end::firstName -->

				  <!-- start::mobile -->
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ numeric: true }"
				  >
					 <b-form-group :label="$t('contacts.mobile')"
								   label-for="mobile">
						<b-input-group
							:class="
                    validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
						>
						   <b-input-group-prepend is-text>
							  <feather-icon icon="SmartphoneIcon" />
						   </b-input-group-prepend>
						   <b-form-input
							   id="mobile"
							   v-model="form.mobile"
							   :state="getValidationState(validationContext)"
							   placeholder="6988888888"
							   trim
						   />
						</b-input-group>
						<b-form-invalid-feedback>
						   {{ $t(validationContext.errors[0]) }}
						</b-form-invalid-feedback
						>
					 </b-form-group>
				  </validation-provider>
				  <!-- end:: mobile -->

				  <!-- start::phone -->
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ numeric: true }"
				  >
					 <b-form-group :label="$t('contacts.phone')"
								   label-for="phone">
						<b-input-group
							:class="
                    validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
						>
						   <b-input-group-prepend is-text>
							  <feather-icon icon="PhoneIcon" />
						   </b-input-group-prepend>
						   <b-form-input
							   id="phone"
							   v-model="form.telephone"
							   :state="getValidationState(validationContext)"
							   placeholder="2101234567"
							   trim
						   />
						</b-input-group>
						<b-form-invalid-feedback>
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback
						>
					 </b-form-group>
				  </validation-provider>
				  <!-- end:: phone -->

				  <!-- start::email -->
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ email: true }"
				  >
					 <b-form-group label="E-mail"
								   label-for="email">
						<b-input-group
							:class="
                    validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
						>
						   <b-input-group-prepend is-text>
							  <feather-icon icon="MailIcon" />
						   </b-input-group-prepend>
						   <b-form-input
							   id="email"
							   v-model="form.email"
							   :state="getValidationState(validationContext)"
							   autofocus
							   placeholder="mail@site.com"
							   trime
							   type="email"
						   />
						</b-input-group>
						<b-form-invalid-feedback>
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback
						>
					 </b-form-group>
				  </validation-provider>
				  <!-- end::email-->

				  <!-- start::submit button -->

				  <div class="d-flex mt-2">
					 <b-button-group class="mr-2">
						<!--						<b-dropdown type="submit"-->
						<!--								  variant="primary"-->
						<!--								  @click="handleSubmit(onSubmit({ reset: true }))">{{ $t('generic.save') }}-->
						<!--						</b-dropdown>-->
						<b-dropdown
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							:disabled="invalid"
							:text="$t('generic.save')"
							left
							split
							variant="primary"
							@click="onSubmit({ reset: true })"
						>
						   <b-dropdown-item
							   @click="onSubmit({ reset: false })"
						   >Αποθήκευση & Νέο
						   </b-dropdown-item>
						   <b-dropdown-item
							   @click="onSubmit({ redirect: true })"
						   >Αποθήκευση & Επεξεργασία
						   </b-dropdown-item>
						</b-dropdown>
					 </b-button-group>

					 <b-button variant="outline-secondary"
							   @click="resetAfterSubmit"
					 >{{ $t("generic.close") }}
					 </b-button>
				  </div>
				  <!-- end::submit button -->
			   </b-form>
			   <!-- end::body -->
			</validation-observer>
		 </div>
	  </template>
   </b-sidebar>
</template>

<script>
import {
   BButton,
   BButtonGroup,
   BDropdown,
   BDropdownItem,
   BForm,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BInputGroup,
   BInputGroupPrepend,
   BSidebar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import {extend, ValidationObserver, ValidationProvider} from "vee-validate";

import {email, numeric, required} from "vee-validate/dist/rules";
import ToastMixin from "@/Mixins/ToastMixin";

extend("required", {
   ...required,
   message: "Υποχρεωτικό πεδίο",
});
extend("numeric", {
   ...numeric,
   message: "Μόνο αριθμοί",
});
extend("email", {
   ...email,
   message: "Μόρφη Email",
});

export default {
   components: {
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BFormInvalidFeedback,
	  BButton,
	  BInputGroup,
	  BInputGroupPrepend,
	  BDropdown,
	  BDropdownItem,
	  BButtonGroup,
	  ValidationObserver,
	  ValidationProvider,
   },
   props: {
	  id: {
		 type: String,
		 default: "sidebar-task-handler",
	  },
   },
   mixins: [ToastMixin],
   data() {
	  return {
		 form: {
			name: "",
			surname: "",
			email: "",
			mobile: "",
			telephone: "",
		 },
	  };
   },
   methods: {
	  closeModal() {
		 this.$root.$emit('bv::toggle::collapse', this.id)
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 if (dirty) return dirty || validated ? valid : null;
	  },
	  async onSubmit({redirect, reset}) {
		 Object.keys(this.form).forEach((key, index) => {
			if (this.form[key] == "") {
			   delete this.form[key];
			}
		 });

		 const response = await this.$store.dispatch(
			 "storeContactCategory",
			 this.form
		 );

		 if (redirect) {
			this.redirectAfterSubmit(response.data.id);
		 }

		 this.resetAfterSubmit(reset);

		 await this.$store.dispatch("fetchAllCategoriesProject", {
			params: {
			   rpp: 999
			}
		 });

		 this.notify(`Αποθηκεύτηκε`);
	  },
	  redirectAfterSubmit(id) {
		 this.$router.push({name: "contacts-edit", params: {userId: id}});
	  },
	  resetAfterSubmit(reset) {
		 if (reset) {
			this.$root.$emit('bv::toggle::collapse', this.id)
			this.$emit("reset-form");
		 }

		 this.$refs.observer.reset();

		 Object.keys(this.form).forEach((key, index) => (this.form[key] = ""));
	  },
   },
   directives: {
	  Ripple,
   },
};
</script>

<style lang="scss">
</style>
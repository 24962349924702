var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":_vm.id,"visible":false,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("generic.new-1"))+" "+_vm._s(_vm.$t("generic.client"))+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal($event)}}})],1)]),_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{ref:"form"},[_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('contacts.surname'),"label-for":"lastName"}},[_c('b-input-group',{staticClass:"input-group-merge",class:validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"lastName","state":_vm.getValidationState(validationContext),"placeholder":"Doe","trim":""},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('contacts.name'),"label-for":"firstName"}},[_c('b-input-group',{staticClass:"input-group-merge",class:validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"firstName","state":_vm.getValidationState(validationContext),"autofocus":"","placeholder":"John","trim":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"form-name-lice-feedback"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ numeric: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('contacts.mobile'),"label-for":"mobile"}},[_c('b-input-group',{class:validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"mobile","state":_vm.getValidationState(validationContext),"placeholder":"6988888888","trim":""},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.$t(validationContext.errors[0]))+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ numeric: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('contacts.phone'),"label-for":"phone"}},[_c('b-input-group',{class:validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"phone","state":_vm.getValidationState(validationContext),"placeholder":"2101234567","trim":""},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ email: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('b-input-group',{class:validationContext.dirty !== false
                      ? validationContext.errors.length
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"email","state":_vm.getValidationState(validationContext),"autofocus":"","placeholder":"mail@site.com","trime":"","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button-group',{staticClass:"mr-2"},[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"disabled":invalid,"text":_vm.$t('generic.save'),"left":"","split":"","variant":"primary"},on:{"click":function($event){return _vm.onSubmit({ reset: true })}}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onSubmit({ reset: false })}}},[_vm._v("Αποθήκευση & Νέο ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.onSubmit({ redirect: true })}}},[_vm._v("Αποθήκευση & Επεξεργασία ")])],1)],1),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.resetAfterSubmit}},[_vm._v(_vm._s(_vm.$t("generic.close"))+" ")])],1)],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }